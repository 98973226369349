import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

import { VendedorInput } from "../../_model/operaciones/vendedor_input";
import { VendedorOutput } from "../../_model/operaciones/vendedor_output";
import { Empresa } from 'src/app/_model/publico/empresa';
import { ListadoVendedores } from "src/app/_model/operaciones/listado_vendedores";
import { EstadoRespuesta } from 'src/app/_model/publico/estado_respuesta';
import { VendedorId } from "src/app/_model/operaciones/vendedor_id";

@Injectable({
  providedIn: 'root'
})
export class VendedorService {

  private url_listar_vendedores = `${environment.HOST}/vendedores/listar`;
  private url_listar_vendedores_por_empresa = `${environment.HOST}/vendedores/listado`;
  private url_registrar = `${environment.HOST}/vendedores/registrar`;
  private url_eliminar = `${environment.HOST}/vendedores/eliminar`;

  constructor(private http: HttpClient) { }

  listarVendedores(content: VendedorInput) {
    return this.http.post<VendedorOutput[]>(this.url_listar_vendedores, content);
  }

  listarVendedoresPorEmpresa(content: Empresa) {
    return this.http.post<ListadoVendedores[]>(this.url_listar_vendedores_por_empresa, content);
  }

  registrar(content: VendedorOutput) {
    return this.http.post<EstadoRespuesta>(this.url_registrar, content);
  }

  eliminar(content: VendedorId) {
    return this.http.post<EstadoRespuesta>(this.url_eliminar, content);
  }
}
