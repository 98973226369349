import { Injectable } from '@angular/core';
import { environment } from '../../../../src/environments/environment';
import { HttpClient } from '@angular/common/http';

import { Empresa } from '../../_model/publico/empresa';
import { Mercaderia } from '../../_model/operaciones/mercaderia';
import { EstadoRespuesta } from 'src/app/_model/publico/estado_respuesta';
import { MercaderiaId } from "src/app/_model/operaciones/mercaderia_id";

@Injectable({
  providedIn: 'root'
})
export class MercaderiaService {

  private url_listar_mercaderias = `${environment.HOST}/mercaderias/listar`;
  private url_listar_mercaderias_por_empresa = `${environment.HOST}/mercaderias/listar_all`;
  private url_registrar = `${environment.HOST}/mercaderias/registrar`;
  private url_eliminar = `${environment.HOST}/mercaderias/eliminar`;

  constructor(private http: HttpClient) { }


  listarMercaderias(content: Empresa) {
    return this.http.post<Mercaderia[]>(this.url_listar_mercaderias, content);
  }

  listarMercaderiasPorEmpresa(content: Empresa) {
    return this.http.post<Mercaderia[]>(this.url_listar_mercaderias_por_empresa, content);
  }

  registrar(content: Mercaderia) {
    return this.http.post<EstadoRespuesta>(this.url_registrar, content);
  }

  eliminar(content: MercaderiaId) {
    return this.http.post<EstadoRespuesta>(this.url_eliminar, content);
  }
}
