import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

import { Empresa } from "../../_model/publico/empresa";
import { Persona } from "../../_model/publico/persona";
import { ListadoPersonasPaginado } from 'src/app/_model/publico/listado_personas_paginado';
import { PersonaInput } from "src/app/_model/publico/persona_input";
import { BancosInput } from "src/app/_model/publico/bancos_input";
import { PersonaBancoInput } from "src/app/_model/publico/personas_banco_input";
import { Bancos} from "src/app/_model/publico/bancos";
import { PersonasDetBancos } from "src/app/_model/publico/personas_det_bancos";
import { EstadoRespuesta } from 'src/app/_model/publico/estado_respuesta';

@Injectable({
  providedIn: 'root'
})
export class PersonaService {

  private url_listar_exportadores = `${environment.HOST}/personas/listar_exportadores`;
  private url_registrar = `${environment.HOST}/personas/registrar`;
  private url_listar_personas = `${environment.HOST}/personas/listar_x_pagina`;
  private url_listar_x_id = `${environment.HOST}/personas/listar_x_id`;
  private url_listar_banco_id = `${environment.HOST}/personas/lista_bancos_persona`;
  private url_listar_banco = `${environment.HOST}/personas/lista_bancos`;
  private url_registrar_datos_banco = `${environment.HOST}/personas/registrar_datosbancos_persona`;
  
 

  constructor(private http: HttpClient) { }

  listarExportadores(content: Empresa) {
    return this.http.post<Persona[]>(this.url_listar_exportadores, content);
  }

  registrarPersona(content: Persona[]) {
    return this.http.post<Persona[]>(this.url_registrar, content);
  }
  listarPersonas(content: ListadoPersonasPaginado) {
    return this.http.post<Persona[]>(this.url_listar_personas, content);
  }

  buscarPersona(content: PersonaInput) {
    return this.http.post<Persona>(this.url_listar_x_id, content);
  }

  listarBancos(content: BancosInput) {
    return this.http.post<Bancos[]>(this.url_listar_banco, content);
  }

  listarDatosBancos(content: PersonaBancoInput) {
    return this.http.post<PersonasDetBancos>(this.url_listar_banco_id, content);
  }

  registrarDatosBancoPersona(content: PersonasDetBancos) {
    return this.http.post<EstadoRespuesta>(this.url_registrar_datos_banco, content);
  }
 
}
